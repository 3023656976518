@font-face {
  font-family: "Satoshi-Medium", sans-serif;
  src: url("../src/fonts/Satoshi-Medium.woff2") format("woff2") url("../src/fonts/Satoshi-Medium.woff") format("woff") url("../src/fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-Bold";
  src: url("../src/fonts/Satoshi-Bold.woff2") format("woff2"),
    url("../src/fonts/Satoshi-Bold.woff") format("woff"),
    url("../src/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

i dl ol ul {
  margin-bottom: 0.5rem;
}

:root {
  --color-bg: #ffffff;
  --color-white: #fff;
  --color-orange: #fd6f20;
  --color-black: #0d0d0d;
  --form-bg: #242424;
  --color-grey: #d9d9d9;
  --heavy: 700;
  --normal: 500;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background-color: var(--color-bg);
  line-height: 1.7;
}

a {
  text-decoration: none;
  color: var(--color-black);
}

a:hover {
  text-decoration: none;
}

h1 h2 h3 h4 h5 h6 {
  font-weight: 500;
}

button:focus {
  outline: none;
}

img {
  object-fit: cover;
  width: 100%;
}

.heavy {
  font-weight: var(--heavy);
}

.normal {
  font-weight: var(--normal);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

section {
  padding-bottom: 120px;
  padding-top: 120px;
}

.justify_center {
  display: flex;
  justify-content: center;
}

.landing_section {
  padding-top: 200px;
  background-image: url("./Assets/Images//background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.landing_main-heading {
  font-family: "Satoshi-Bold";
  color: var(--color-white);
  font-size: 64px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2px;
}

.landing_paragraph {
  font-family: "Satoshi-Medium", sans-serif;
  margin-top: 40px;
  color: var(--color-white);
  font-size: 18px;
  font-style: normal;
  line-height: normal;
}

.listening_btn {
  margin-top: 56px;
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background: var(--color-orange);
  transition: 0.3s;
}

.listening_btn:hover {
  gap: 24px;
}

.listen_text {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-white);
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.icon-arrow {
  width: 20px;
}

.margin_segment {
  margin-bottom: 56px;
}

.margin_clips {
  margin-bottom: 40px;
  transition: 0.3s;
}

.segment_heading {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-black);
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  opacity: 0.4;
}

.segment_main-heading {
  font-family: "Satoshi-Bold";
  color: var(--color-black);
  font-size: 48px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2px;
}

.heading-clip {
  margin-top: 16px;
  color: var(--color-black);
  opacity: 0.9;
  font-family: "Satoshi-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
}

.date {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-black);
  opacity: 0.3;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
}

.about-section {
  background-image: url("./Assets/Images/about-background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.about-subtext {
  color: var(--color-white);
  font-family: "Satoshi-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  opacity: 0.4;
  line-height: normal;
  letter-spacing: -0.5px;
}

.about_main-heading {
  font-family: "Satoshi-Bold";
  margin-bottom: 48px;
  color: var(--color-white);
  font-size: 48px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2px;
}

.about-paragraph {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.margin_about-paragraph {
  margin-top: 16px;
}

.container-social {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: var(--color-white);
  height: 350px;
}

.social-img {
  width: 100px;
}

.social-subtext {
  font-family: "Satoshi-Medium", sans-serif;
  margin-top: 16px;
  color: var(--color-black);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  opacity: 0.4;

  line-height: normal;
}

.social-heading {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-black);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
}

.contact-section {
  background-image: url("./Assets/Images/contact-bg.png");
  background-color: var(--color-black);
  background-size: cover;
  background-repeat: no-repeat;
}

.form-align {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: self-start;
}

label {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.5px;
}

form>div>div>input {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-white);
  caret-color: var(--color-white);
  border-radius: 5px;
  background: var(--form-bg);
  width: 100%;
  height: 54px;
  padding: 0 24px;
  margin-bottom: 24px;
}

.form-flex {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  flex-direction: row;
}

textarea {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-white);
  caret-color: var(--color-white);
  border-radius: 5px;
  background: var(--form-bg);
  width: 100%;
  height: 140px;
  border: none;
  resize: none;
  outline: none;
  padding: 24px;
}

.logo {
  width: 55px;
  height: 45px;
  flex-shrink: 0;
}

footer {
  padding-top: 96px;
  padding-bottom: 56px;
  background: var(--color-grey);
}

.logo-box {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 32px;
}

.social-icon {
  width: 24px;
}

.social-box {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: start;
}

.links-heading {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.space_gap {
  padding: 88px;
  width: 100%;
}

hr {
  height: 2px;
  flex-shrink: 0;
  border-radius: 1px;
  opacity: 0.1;
  background: var(--color-black);
}

.margin_to-line {
  margin-bottom: 92px;
}

.reserve-heading {
  font-family: "Satoshi-Medium", sans-serif;
  color: var(--color-black);
  opacity: 0.5;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  margin-top: 16px;
  line-height: normal;
}

.header-absolute {
  position: absolute;
  padding-top: 60px;
}

.margin_clips:hover h2 {
  text-decoration: underline;
}




@media (max-width: 767px) {
  .social-box {
    justify-content: center;
  }

  .landing_section {
    padding-top: 150px;
  }

  .column-reverse {
    flex-direction: column-reverse;
  }

  .gap-footer {
    gap: 20px;
  }

  .column {
    flex-direction: column;
  }

  .align_start {
    align-items: start;
  }

  .landing_main-heading {
    font-size: 35px;
  }

  .segment_main-heading {
    font-size: 40px;
  }

  .segment_heading {
    font-size: 14px;
  }

  .about_main-heading {
    font-size: 30px;
  }

  .about-subtext {
    font-size: 14px;
  }

  .about-paragraph {
    font-size: 14px;
  }

  .margin_bottom {
    margin-bottom: 32px;
  }
}